import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ExportBtn from "../../components/Admin/TotalHospital/ui/ExportBtn";
import LoaderOverlay from "../../components/Loader/LoaderOverlay";
import {
  emerFilterColDefination,
  emerHeaderObj,
} from "../../components/ProductEmerType/colDefination";
import { TableFilterBtn } from "../../components/UI/DataTable";

const hospitalId = localStorage.getItem("hospitalid");

export default function ProductByEmerTypeScreen() {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [visibleCols, setVisilbleCols] = useState({
    proName: true,
    category: true,
    date: true,
    origin: true,
    manufac: true,
    type: true,
    subCate: true,
  });
  const [emergencyType, setEmergencyType] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      const queryParams = new URLSearchParams({ proImg: false });
      const url = `${process.env.REACT_APP_BASE_URL}productbyhospitalid/${hospitalId}?${queryParams}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setProducts(data.products);
          return;
        }
        throw new Error(response.statusText);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    getProducts();
  }, []);

  const columns = emerFilterColDefination.filter((el) => visibleCols[el.field]);

  function generateRows(products, emergencyType) {
    let rows =
      products && products.length > 0
        ? products.map((el) => ({
            proName: el.name,
            category: el.category,
            date: el.date,
            origin: el.origin,
            manufac: el.manufacturer,
            type: el.producttype,
            subCate: el.subcategory,
            id: el._id,
            emer: el.emergencytype,
          }))
        : [];

    if (rows.length > 0) {
      if (emergencyType === 2) {
        rows = rows.filter((el) => el.emer === "Critical");
      } else if (emergencyType === 3) {
        rows = rows.filter((el) => el.emer === "Non Critical");
      }
    }

    return rows;
  }

  const rows = generateRows(products, emergencyType);

  //Column filter button handlers and state
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);
  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };
  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };
  const toggleColumnVisibility = (column) => {
    setVisilbleCols((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const visibleColsKeyArr = Object.keys(visibleCols);

  ///********************************************//
  //export button state and handlers
  const selectedDataObj =
    rows.length > 0
      ? rows.filter((el) => selectedRows.includes(el.id.toString()))
      : [];

  const selectedData = [];
  selectedDataObj.forEach((item) => {
    const a = [];
    visibleColsKeyArr.forEach((key) => {
      if (visibleCols[key]) {
        a.push(item[key]);
      }
    });

    selectedData.push(a);
  });
  const headerObj = emerHeaderObj;
  const headers = [];
  visibleColsKeyArr.forEach((key) => {
    if (visibleCols[key]) {
      headers.push(headerObj[key]);
    }
  });

  return (
    <main className="main-container">
      <LoaderOverlay loading={loading} />
      <div>
        <section
          className="p-5 w-100"
          style={{
            backgroundColor: "#eeeee",
            borderRadius: ".5rem .5rem 0 0",
          }}
        >
          <div className="row">
            <div className="col">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-3">
                  <div className="card-body p-md-3">
                    <Typography
                      variant="h4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        color: "black",
                        padding: "10px",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                      }}
                    >
                      Products
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl sx={{ width: 300 }}>
                        <InputLabel id="emergency-type-label">
                          Emergency type
                        </InputLabel>
                        <Select
                          labelId="emergency-type-label"
                          id="emergency-type-select"
                          value={emergencyType}
                          label="Emergency Type"
                          onChange={(e) => {
                            setEmergencyType(e.target.value);
                          }}
                        >
                          <MenuItem value={1}>All</MenuItem>
                          <MenuItem value={2}>Critical</MenuItem>
                          <MenuItem value={3}>Non critical</MenuItem>
                        </Select>
                      </FormControl>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TableFilterBtn
                          anchorEl={columnAnchorEl}
                          onClose={handleColumnClose}
                          onClick={handleColumnClick}
                          columnDefinitions={emerFilterColDefination}
                          visibleColumns={visibleCols}
                          onChange={toggleColumnVisibility}
                        />
                        <ExportBtn
                          rows={selectedData}
                          isSelected={true}
                          headers={headers}
                          fileName="Report"
                        />
                      </Stack>
                    </Stack>
                    <Box sx={{ height: 700, width: "100%", marginTop: "20px" }}>
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        disableColumnMenu
                        disableColumnSorting
                        checkboxSelection={true}
                        onRowSelectionModelChange={(e) => {
                          setSelectedRows(e);
                        }}
                        rowSelectionModel={selectedRows}
                        sx={{
                          "& .MuiTablePagination-displayedRows": {
                            marginTop: 0,
                            marginBottom: 0,
                          },
                          "& .MuiTablePagination-selectLabel": {
                            marginTop: 0,
                            marginBottom: 0,
                          },
                          "& .MuiDataGrid-columnHeaderTitleContainer": {
                            color: "#2E718A",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-cellContent": {
                            color: "black",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
