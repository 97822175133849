export const expiringProColDefination = [
  {
    field: "date",
    headerName: "DATE OF EXPIRATION",
    headerAlign: "left",
    width: 200,
    align: "left",
  },
  { field: "proName", headerName: "PRODUCT NAME", width: 200 },
  {
    field: "type",
    headerName: "PRODUCT TYPE",
    headerAlign: "left",
    width: 200,
    align: "left",
  },
  { field: "category", headerName: "CATEGORY", width: 200 },
  {
    field: "manufac",
    headerName: "MANUFACTURER",
    width: 200,
  },
  { field: "origin", headerName: "ORIGIN", width: 150 },
  {
    field: "subCate",
    headerName: "SUB CATEGORY",
    width: 200,
  },
  {
    field: "emer",
    headerName: "EMERGENCY TYPE",
    width: 200,
  },
];

export const expiringHeaderObj = {
  proName: "PRDUCT NAME",
  date: "DATE OF EXPIRATION",
  category: "CATEGORY",
  origin: "ORIGIN",
  manufac: "MANUFACTURER",
  type: "TYPE",
  subCate: "SUB CATEGORY",
  emer: "EMERGENCY TYPE",
};
