import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import {
  expiringHeaderObj,
  expiringProColDefination,
} from "./expirinProColDefination";

export default function ExpiringProductTable({ products }) {
  // *****************************************************//
  // rows generation
  function generateRows(products) {
    if (!products || products.length === 0) return [];
    const rows = products.map((el) => ({
      proName: el.productid.name,
      category: el.productid.category,
      date: el.doe,
      origin: el.productid.origin,
      manufac: el.productid.manufacturer,
      type: el.productid.producttype,
      subCate: el.productid.subcategory,
      id: el._id,
      emer: el.productid.emergencytype,
    }));

    return rows;
  }
  const rows = generateRows(products);

  //******************************************************//
  //Column filter button handlers and state
  const [visibleCols, setVisilbleCols] = useState({
    proName: true,
    category: true,
    date: true,
    origin: true,
    manufac: true,
    type: true,
    subCate: true,
    emer: true,
  });
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);
  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };
  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };
  const toggleColumnVisibility = (column) => {
    setVisilbleCols((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };
  const columns = expiringProColDefination.filter(
    (el) => visibleCols[el.field],
  );

  ///********************************************//
  //export button state and handlers
  const visibleColsKeyArr = Object.keys(visibleCols);
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedDataObj =
    rows.length > 0
      ? rows.filter((el) => selectedRows.includes(el.id.toString()))
      : [];

  const selectedData = [];
  selectedDataObj.forEach((item) => {
    const a = [];
    visibleColsKeyArr.forEach((key) => {
      if (visibleCols[key]) {
        a.push(item[key]);
      }
    });

    selectedData.push(a);
  });
  const headerObj = expiringHeaderObj;
  const headers = [];
  visibleColsKeyArr.forEach((key) => {
    if (visibleCols[key]) {
      headers.push(headerObj[key]);
    }
  });

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <TableFilterBtn
          anchorEl={columnAnchorEl}
          onClose={handleColumnClose}
          onClick={handleColumnClick}
          columnDefinitions={expiringProColDefination}
          visibleColumns={visibleCols}
          onChange={toggleColumnVisibility}
        />
        <ExportBtn
          rows={selectedData}
          isSelected={true}
          headers={headers}
          fileName="Stockout_Report"
        />
      </Stack>
      <Box sx={{ height: 700, width: "100%", marginTop: "20px" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          disableColumnMenu
          disableColumnSorting
          checkboxSelection={true}
          onRowSelectionModelChange={(e) => {
            setSelectedRows(e);
          }}
          rowSelectionModel={selectedRows}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              marginTop: 0,
              marginBottom: 0,
            },
            "& .MuiTablePagination-selectLabel": {
              marginTop: 0,
              marginBottom: 0,
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              color: "#2E718A",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cellContent": {
              color: "black",
            },
          }}
        />
      </Box>
    </>
  );
}
