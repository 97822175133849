const a = {
  proName: true,
  category: true,
  date: true,
  origin: true,
  manufac: true,
  type: true,
  subCate: true,
};

const width = 180;
const sortable = false;

export const emerFilterColDefination = [
  { field: "date", headerName: "DATE", headerAlign: "left", sortable, width },
  {
    field: "proName",
    headerName: "PRODCUCT NAME",
    headerAlign: "left",
    sortable,
    width,
  },
  { field: "type", headerName: "TYPE", headerAlign: "left", sortable, width },
  {
    field: "category",
    headerName: "CATEGORY",
    headerAlign: "left",
    sortable,
    width,
  },
  {
    field: "subCate",
    headerName: "SUB CATEGORY",
    headerAlign: "left",
    sortable,
    width,
  },
  {
    field: "manufac",
    headerName: "MANUFACTURER",
    headerAlign: "left",
    sortable,
    width,
  },
  {
    field: "origin",
    headerName: "ORIGIN",
    headerAlign: "left",
    sortable,
    width,
  },
];

export const emerHeaderObj = {
  proName: "PRDUCT NAME",
  category: "CATEGORY",
  date: "DATE",
  origin: "ORIGIN",
  manufac: "MANUFACTURER",
  type: "TYPE",
  subCate: "SUB CATEGORY",
};
